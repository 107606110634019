<template>

  <div class="h-95vh w-screen flex flex-col items-center">
      <img class="h-4.5 mt-8" src="@/assets/logo.png" alt="" />
      <h1 class="text-175 mx-0 my-0 text-center font-bold site-title"> Bespoke Luxury Rentals </h1>
    <h1 class="text-300 font-bold site-title mt-20"> NEW WEBSITE COMING SOON!</h1>
  </div>

  <footer class="h-5vh bg-dark-2 w-screen flex justify-center items-center">
      <p class="pt-4 pb-4 text-center text-base text-gray-200"> Designed and Maintained by <a class="font-bold hover:text-gray-400" href="https://www.classicniall.co.uk" target="_blank">Classic Niall</a> </p>
  </footer>

</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

</style>